
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import EntityTable from "@/components/EntityTable.vue";
import TableExplorer from "@/components/TableExplorer.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Newsfilters from "@/components/NewsFilters.vue";
import { useStore } from "vuex";
import moment from "moment";

export default defineComponent({
  name: "kt-widget-12",
  components: {
    Newsfilters,
    TableExplorer,
  },
  props: {
    widgetClasses: String,
  },
  methods: {
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    markAsRead(id) {
      ApiService.post("news/" + id + "/read", {})
        .then(() => {
          (this.$refs.newsTable as any).retrieveData();
        })
        .catch(this.catchErrors);
    },
    getStatusText(status) {
      switch (status) {
        case "open":
          return "Abierto";
        case "closed":
          return "En Tratamiento";
        case "processed":
          return "Cerrado";
      }

      return "Desconocido";
    },
    getStatusClass(status) {
      switch (status) {
        case "open":
          return "-danger";
        case "closed":
          return "-warning";
        case "processed":
          return "-success";
      }

      return "Desconocido";
    },
  },
  data() {
    return {
      filters: {
        dateBetween: [
          moment().subtract(7, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      columns: [
        {
          name: "date",
          label: "Fecha",
        },
        {
          name: "status",
          label: "Estado",
        },
        {
          name: "supervisor_name",
          label: "Supervisora",
        },
        {
          name: "visitor_name",
          label: "Visitadora",
        },
        {
          name: "donor_count",
          label: "Donantes",
        },
      ],
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
});
