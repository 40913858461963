
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";

export default defineComponent({
  name: "newsfilters",
  props: ["applied_filters"],
  data() {
    return {
      newsTypeOptions: {},
      statusOptions: {
        open: "Abierto",
        closed: "En tratamiento",
        processed: "Cerrado",
      },
      filters: this.applied_filters,
    };
  },
  emits: ["changed"],
  methods: {
    dataChanged() {
      this.$emit("changed", this.filters);
    },
  },
  mounted() {
    ApiService.query("index/newstypes", {
      params: {},
    }).then((res) => {
      this.newsTypeOptions = res.data;
    });
  }
});
